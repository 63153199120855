import React, { Component } from "react";
import { Map } from '@mui/icons-material';
import ReactMapGL, { Marker,FullscreenControl,NavigationControl,Source,Layer, GeolocateControl, ScaleControl } from "react-map-gl";
const bounds = [
  [82.23816427684926,16.97714323383545], // Southwest coordinates
  [82.24916854639105,16.98493540220099] // Northeast coordinates
  ];
  
export class Main extends Component {
  constructor(props){
    super(props)
    this.state = {
      viewPort:{
        latitude: props.currentLocation[0],
        longitude: props.currentLocation[1],
        zoom: 19,
        maxZoom: 21,
        bearing: 114,
        pitch:90
      },
      currentLocation:props.currentLocation,
      data:null,
      route:null,
      numberOfRoutes:props.data != null ? props.data.routes.length : 0,
      routeNumber:0,
      mapStyle:[
        "mapbox://styles/hrushikesh18/cl9o8r3vy00lw15l3fylv28mk",
        "mapbox://styles/hrushikesh18/cl8mfbbuv001t15mrb2m8gysp"
      ],
      styleNumber:0,
      startLocation:props.data != null ? [this.props.data.routes[0].geometry.coordinates[0][0],this.props.data.routes[0].geometry.coordinates[0][1]] : null
    }
  }

 route = () => {
  if(this.props.data != null){
    var data = this.props.data;
    console.log(data.routes[0].legs[0].steps[0].intersections[0].location)
    var coordinates = []
    data.routes[0].legs[0].steps.forEach(steps => {
      steps.intersections.forEach(intersections => {
        coordinates.push(intersections.location)
      });
    });
    var route =  {
      type: "Feature",
      properties: {},
      geometry: data.routes[this.state.routeNumber].geometry
    }
  return(
    <Source id="polylineLayer" type="geojson" data={route}>
    <Layer
      id="lineLayer"
      type="line"
      source="my-data"
      layout={{
        "line-join": "round",
        "line-cap": "round"
      }}
      paint={{
        "line-color": "rgba(3, 170, 238, 0.5)",
        "line-width": 5
      }}
    />
  </Source>
  )
    }
 }

 componentDidUpdate(){
  var x = this.state.viewPort;
  
  if(x.latitude != this.props.currentLocation[0] || x.longitude != this.props.currentLocation[1]){
  x.latitude = this.props.currentLocation[0]
  x.longitude = this.props.currentLocation[1]
  this.setState({
    viewPort:x
  })
  console.log(this.state.viewPort)
}
 }
 DestinationMarker = () => {
  if(this.props.data != null){
    var finalLen = this.props.data.routes[0].geometry.coordinates.length;
  return(
    <Marker color="green" latitude={this.props.data.routes[0].geometry.coordinates[finalLen-1][1]} longitude={this.props.data.routes[0].geometry.coordinates[finalLen-1][0]} />
  )
  }
 }
 InitalMarker = () => {
  if(this.props.data != null){
  return(
    <Marker  latitude={this.props.data.routes[0].geometry.coordinates[0][1]} longitude={this.props.data.routes[0].geometry.coordinates[0][0]} />
  )
  }
 }
  render() {
    return (
        <ReactMapGL
          maxBounds={bounds}
          initialViewState={this.state.viewPort}
          style={{ width: "100vw", height: "97vh" }}
          mapboxAccessToken={
            process.env.REACT_APP_MAPBOX_TOKEN
          }  
         mapStyle={this.state.mapStyle[this.state.styleNumber]}
        >
          {this.InitalMarker()}
          <Marker latitude={this.props.currentLocation[0]} longitude={this.props.currentLocation[1]} >
            <div style={{width:20,height:20,borderRadius:20,backgroundColor:"#18ba20",color:"#000",display:"flex",justifyContent:"center",alignItem:"center"}}>
              P
            </div>
          </Marker>
          {this.DestinationMarker()}
          <div style={{marginTop:60}}>
          <GeolocateControl position="bottom-left"/>
          </div>
          <div style={{marginBottom:60}}>
          <FullscreenControl position="bottom-right" />
          </div>
          <NavigationControl position="bottom-left" />
          <ScaleControl />
          <div onClick={()=>{
           this.props.reset()
          }} style={{cursor:"pointer",backgroundColor:"#f0f0f0",width:30,height:30,borderRadius:4,position:"absolute",justifyContent:"center",alignItems:"center",display:"flex",bottom:"22%",right:"3%",zIndex:5}}>
          <p style={{fontWeight:"bold",color:"red",userSelect:"none"}}>R</p>
          </div>
          <div onClick={()=>{
           this.state.styleNumber == 1 ? this.setState({styleNumber:0}) : this.setState({styleNumber:1})
          }} style={{cursor:"pointer",backgroundColor:"#f0f0f0",width:30,height:30,borderRadius:4,position:"absolute",justifyContent:"center",alignItems:"center",display:"flex",bottom:"17%",right:"3%",zIndex:5}}>
          <Map />
          </div>
          <div onClick={()=>{
            var numberOfRoutes = this.props.data != null ? this.props.data.routes.length : null;
            if(this.state.routeNumber < numberOfRoutes - 1){
              this.setState(prevstate => ({ routeNumber: prevstate.routeNumber + 1}));
            }else{
              if(this.state.routeNumber != 0){
              this.setState(prevstate => ({ routeNumber: prevstate.routeNumber - 1}));
              }
            }
          }} style={{cursor:"pointer",backgroundColor:"#f0f0f0",width:30,height:30,borderRadius:4,position:"absolute",justifyContent:"center",alignItems:"center",display:"flex",bottom:"12%",right:"3%",zIndex:5}}>
          <p style={{fontWeight:"bold",userSelect:"none"}}>{this.state.routeNumber+1}</p>
          </div>
          
            {this.route()}
            
        </ReactMapGL>
    );
  }
}

export default Main;

