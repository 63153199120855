import { useEffect, useState } from 'react';
import './App.css';
import Header from './Components/Header';
import Main from './Components/Main';

const bounds = [
  
    [
      16.985192744757654,
      82.24094560661456
    ],
    [
      16.978268111404276,
      82.23814820771884
    ],
    [
      16.9761041110731,
      82.24477146098673
    ],
    [
      16.983225545363283,
      82.24703406303558
    ],
    [
      16.985192744757654,
      82.24094560661456
    ]
  ]// Southwest coordinates // Northeast coordinates;
  
function App() {
  const [data,setData] = useState(null)
  const [currentLocation,setCurrentLocation] = useState([
    16.980740, 82.240025
  ]);
  useEffect(()=>{
    getLocation()
  },[data])
  var id = null;
  const success = (pos) => {
    console.log(pos)
    const crd = pos.coords;
    console.log(inBounds([crd.latitude,crd.longitude],bounds))
    console.log(inBounds([16.980100525272604,82.24312396799519],bounds))
  if(inBounds([crd.latitude,crd.longitude],bounds)){
    setCurrentLocation([crd.latitude,crd.longitude]) 
   }
   else{
    setCurrentLocation([16.980100525272604,82.24312396799519])
   }
    if(data != null){
    var finalLen = data.routes[0].geometry.coordinates.length;
    console.log(data.routes[0].geometry.coordinates[finalLen-1])
    if (Math.abs(data.routes[0].geometry.coordinates[finalLen-1][1] - crd.latitude) < 0.001 && Math.abs(data.routes[0].geometry.coordinates[finalLen-1][0] - crd.longitude) < 0.001) {
      console.log('Congratulations, you reached the target');
      navigator.geolocation.clearWatch(id);
      setData(null)
    }
  }
  }
  

  var inBounds = (point,vs) => {
    var x = point[0], y = point[1];
    
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i][0], yi = vs[i][1];
        var xj = vs[j][0], yj = vs[j][1];
        
        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    
    return inside;
  }
  const getLocation = () => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
    } else {
      const options = {
        enableHighAccuracy: true,
        timeout: 2000,
        maximumAge: 1000,
      };
      
      id = navigator.geolocation.watchPosition(success,(err)=>{alert(`ERROR(${err.code}): ${err.message}`)}, options);
    }
  }
  console.log(data)
  return ( 
    <div className="App">
    {/* Header Section */} 
      <Header currentLocation={currentLocation} searchData={data=>{setData(data)}} className="header" />
    {/* Map Section */}
      <Main currentLocation={currentLocation} reset={()=>setData(null)}  data={data} className="main" />
    {/* Footer Section */}
    </div> 
  );
}

export default App;
