import React, { Component } from 'react'
import {} from '@mui/material'
import { SearchRounded, CenterFocusWeakOutlined, ViewInArOutlined, SignalWifiStatusbarNullRounded } from '@mui/icons-material'

export class Header extends Component {
  constructor(props){
    super(props)
    this.state = {
      isSearching:false,
      searchingText:"",
      searchResults:null,
      
    }
  }
  handleChange = (props) => {
    if(this.state.searchingText.length > props.target.value.length){
      this.setState({
        searchResults:null,
        searchingText:props.target.value
      })
    }else{
    this.setState({
      searchingText:props.target.value
    })
    console.log(props.target.value) 
    this.getInformation()
  }
  }
  getRoute = (i) => {
    this.setState({
      searchResults:null
    })
    fetch(`https://locus.hrushispace.com/api/getRouteInfo.php`,{
      method:"POST",
      headers:{
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({
        iLat:`${this.props.currentLocation[1]}`,
        iLong:`${this.props.currentLocation[0]}`,
        fLat:`${this.state.searchResults[i][2]}`,
        fLong:`${this.state.searchResults[i][1]}`
      })
    })
    .then(response=>response.json())
    .then(res=>this.props.searchData(res))
    .catch(error=>console.log(error))
  }
  showResults = () => {
    if(this.state.searchResults != null){
    return this.state.searchResults.map((data,index)=>{
      return(
        <div onClick={()=>{
          this.getRoute(index)
        }} key={index} style={{cursor:"pointer",width:"90%",marginBottom:3,alignItems:"center",justifyContent:"center",height:40,marginLeft:12}}>
          <p style={{marginTop:5}}>
            {data[0]}
          </p>
        </div>
      )
    })
  }
  }
  getInformation = () => {
    fetch(`https://locus.hrushispace.com/api/getSearchInformation.php?action=search&place=${this.state.searchingText}`)
    .then(response=>response.json())
    .then(res=>{
      console.log(res)
      this.setState({
      searchResults:res
    })})
    .catch(error=>console.log(error))
  }
  render() {
    return (
      <header>
          <img className='logo' src='https://cdn.cdnlogo.com/logos/t/4/temp.svg' alt='logo' />
          <div className='srcbox'>
            <SearchRounded className='searchicon' />
            <input value={this.state.searchingText} onChange={this.handleChange} type={'text'} placeholder={'Search'} />
          </div>
          <div style={{position:"absolute",overflowY:"scroll",top:65,width:300,left:"20%",maxHeight:400,backgroundColor:"#fff",borderRadius:12,elevation:12,zIndex:5}}>
            {this.showResults()}
          </div>
          <div className='qr'>
            <CenterFocusWeakOutlined className='lens' />
            <p className='title'>Open lens</p>
          </div>
          <div className='ar'>
            <ViewInArOutlined className='ar-icon' />
            <p className='title'>Navigate with AR</p>
          </div>
      </header>
    )
  }
} 

export default Header